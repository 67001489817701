








import { Component } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../_abstract'

import { StatsModule } from '../Stats.contracts'

/**
 * Container component for the `StatsModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<StatsModuleUi>({
  name: 'StatsModuleUi',
  components: {
    StatsModuleUiPresentation: () => import(
      /* webpackChunkName: "modules/Stats" */
      './Stats.ui.presentation.vue'
    )
  }
})
export class StatsModuleUi extends AbstractModuleUi<StatsModule> {
  /**
   * Determines whether the component should be rendered.
   */
  public get shouldRender (): boolean {
    return typeof this.data !== 'undefined' &&
      typeof this.data.content !== 'undefined' &&
      typeof this.data.version === 'string'
  }
}

export default StatsModuleUi
