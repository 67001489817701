






import { AsyncComponent, VueConstructor } from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { log } from '../../../../support'

import { AbstractModuleUiPresentation } from '../../_abstract'

import {
  StatsModuleBaseContent,
  StatsModuleCircleVersionContent,
  StatsModuleTextVersionContent,
  StatsModuleVersion
} from '../Stats.contracts'

import { statsModuleVersionComponentRegistry } from './Stats.ui.config'

/**
 * Presentational component for the `StatsModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<StatsModuleUiPresentation>({ name: 'StatsModuleUiPresentation' })
export class StatsModuleUiPresentation extends AbstractModuleUiPresentation {
  /**
   * @see StatsModuleBaseContent.label
   */
  @Prop({ type: String, required: true })
  public readonly label!: StatsModuleBaseContent['label']

  /**
   * @see StatsModuleCircleVersionContent.size
   */
  @Prop({ type: String, required: false })
  public readonly size?: StatsModuleCircleVersionContent['size']

  /**
   * @see StatsModuleTextVersionContent.source
   */
  @Prop({ type: String, required: false })
  public readonly source?: StatsModuleTextVersionContent['source']

  /**
   * @see StatsModuleBaseContent.value
   */
  @Prop({ type: String, required: true })
  public readonly value!: StatsModuleBaseContent['value']

  /**
   * @see StatsModuleTextVersionContent.valuePosition
   */
  @Prop({ type: String, required: false })
  public readonly valuePosition?: StatsModuleTextVersionContent['valuePosition']

  /**
   * Version of the module. Determines which Vue component
   * will be used to render the offers.
   *
   * @see StatsModuleVersion
   */
  @Prop({ type: String, required: true })
  private readonly version!: StatsModuleVersion

  /**
   * Vue component that should be used to render the job offers.
   */
  public get component (): VueConstructor | AsyncComponent | undefined {
    const component = statsModuleVersionComponentRegistry[this.version]

    if (typeof component === 'undefined') {
      log(`StatsModuleUiPresentation.component(): There's no Vue component associated with the [${this.version}] StatsModuleVersion!`, 'error')
      return
    }

    return component
  }

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return this.hasComponent && this.hasLabel && this.hasValue
  }

  /**
   * Determines whether the Vue component applicable for the given `StatsModuleVersion`
   * has been successfully resolved from the `statsModuleVersionComponentRegistry`.
   */
  private get hasComponent (): boolean {
    return typeof this.component === 'function'
  }

  /**
   * Determines whether the component has been provided with the correct `label` prop.
   */
  private get hasLabel (): boolean {
    return typeof this.label === 'string' && this.label.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `value` prop.
   */
  private get hasValue (): boolean {
    return typeof this.value === 'string' && this.value.length > 0
  }
}

export default StatsModuleUiPresentation
