












import { Component, Prop, Vue } from 'vue-property-decorator'

import { StatsModuleBaseContent, StatsModuleTextVersionContent } from '../../../Stats.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<StatsText>({ name: 'StatsText' })
export class StatsText extends Vue {
  /**
   * @see StatsModuleBaseContent.label
   */
  @Prop({ type: String, required: true })
  public readonly label!: StatsModuleBaseContent['label']

  /**
   * @see StatsModuleTextVersionContent.source
   */
  @Prop({ type: String, required: false })
  public readonly source?: StatsModuleTextVersionContent['source']

  /**
   * @see StatsModuleBaseContent.value
   */
  @Prop({ type: String, required: true })
  public readonly value!: StatsModuleBaseContent['value']

  /**
   * @see StatsModuleTextVersionContent.valuePosition
   */
  @Prop({ type: String, required: false, default: 'top' })
  public readonly valuePosition?: StatsModuleTextVersionContent['valuePosition']

  /**
   * Determines whether the component has been provided with the correct `source` prop.
   */
  public get hasSource (): boolean {
    return typeof this.source === 'string' && this.source.length > 0
  }

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return this.hasLabel && this.hasValue
  }

  /**
   * Determines whether the component has been provided with the correct `label` prop.
   */
  private get hasLabel (): boolean {
    return typeof this.label === 'string' && this.label.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `value` prop.
   */
  private get hasValue (): boolean {
    return typeof this.value === 'string' && this.value.length > 0
  }
}

export default StatsText
