// Copyright © 2021 Move Closer

import { AsyncComponent, VueConstructor } from 'vue'

import { StatsModuleVersion } from '../Stats.contracts'

/**
 * Registry that binds the `StatsModuleVersion` with the applicable Vue component.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const statsModuleVersionComponentRegistry: Record<StatsModuleVersion, VueConstructor | AsyncComponent> = {
  [StatsModuleVersion.Circle]: () => import(
    /* webpackChunkName: "modules/Stats" */
    './partials/StatsCircle/StatsCircle.vue'
  ),
  [StatsModuleVersion.Text]: () => import(
    /* webpackChunkName: "modules/Stats" */
    './partials/StatsText/StatsText.vue'
  )
}
