






import { Component, Prop, Vue } from 'vue-property-decorator'

import { UiDataCircleSize } from '../../../../../atoms'

import { StatsModuleBaseContent, StatsModuleCircleVersionContent } from '../../../Stats.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<StatsCircle>({
  name: 'StatsCircle',
  components: {
    UiDataCircle: () => import(
      /* webpackChunkName: "atoms/UiDataCircle" */
      '../../../../../atoms/UiDataCircle/UiDataCircle.vue'
    )
  }
})
export class StatsCircle extends Vue {
  /**
   * @see StatsModuleBaseContent.label
   */
  @Prop({ type: String, required: true })
  public readonly label!: StatsModuleBaseContent['label']

  /**
   * @see StatsModuleCircleVersionContent.size
   */
  @Prop({ type: String, required: false, default: UiDataCircleSize.Small })
  public readonly size?: StatsModuleCircleVersionContent['size']

  /**
   * @see StatsModuleBaseContent.value
   */
  @Prop({ type: String, required: true })
  public readonly value!: StatsModuleBaseContent['value']

  /**
   * Determines whether the component has been provided with the correct `label` prop.
   */
  public get hasLabel (): boolean {
    return typeof this.label === 'string' && this.label.length > 0
  }
}

export default StatsCircle
